import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { GoogleOAuthProvider } from '@react-oauth/google'
import {clientId} from './env'
createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={clientId()}>
      <App />
    </GoogleOAuthProvider>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// 335131290718-oq0fi2sf0jn8ndmmpmqkmb1stt2f7knu.apps.googleusercontent.com
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
