import React, {Component} from 'react'
import {googleLogout} from '@react-oauth/google'
import jwt_decode from 'jwt-decode'
import './scss/style.scss'
//import WHITE_LISTED_EMAIL_IDS from './whitelistAccessToDS.js'
import axios from 'axios'
import {clientId, serviceEndPoint} from './env.js'
import {
  CFormLabel,
  CFormInput,
  CFormCheck,
  CButton,
  CForm,
  CCol,
  CRow,
  CFormTextarea,
  CSpinner,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CAlert
} from '@coreui/react';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
const WHITE_LISTED_EMAIL_IDS = ['saksham.soni@salesforce.com', 'apoorva.kaushal@salesforce.com', 'sparsh.verma@salesforce.com',
  'akshat.aggarwal@salesforce.com', 'lborse@salesforce.com', 'hgoverdhan@salesforce.com',
  'aayushgupta@salesforce.com', 'snahta@salesforce.com', 'shayambhu.chaudhuri@salesforce.com',
  'reddy.a@salesforce.com', 'annant.maheshwari@salesforce.com', 'raghav.goel@salesforce.com', 'misrani@salesforce.com',
   'sansriti.maurya@salesforce.com', 'anjali@salesforce.com', 'arush.kumar@salesforce.com', 'shashank.shanbhag@salesforce.com',
    'smogilichendu@salesforce.com', 'mgrewal@salesforce.com', 'pberlia@salesforce.com', 'tejaswi.hegde@salesforce.com',
    'mrinalanand@salesforce.com', 'smathpal@salesforce.com', 'dchitkara@salesforce.com', 'gaddamreddy@salesforce.com',
    'padalasudheer.reddy@salesforce.com', 'kunal.rastogi@salesforce.com', 'amankumar2@salesforce.com', 'sutkarsh@salesforce.com',
    'nimish.bhatia@salesforce.com', 'ram.singh@salesforce.com', 'shantanumishra@salesforce.com', 'harsh.malik@salesforce.com',
    'ankush.bhagat@salesforce.com', 'adityagarg@salesforce.com', 'esahu@salesforce.com', 'atelu@salesforce.com', 'debayan.saha@salesforce.com',
    'manvendrapratapsingh@salesforce.com', 'husmani@salesforce.com', 'arnav.gupta@salesforce.com', 'kush.aggarwal@salesforce.com',
    'nikhilg@salesforce.com', 'sarandeep.mannam@salesforce.com', 'ayan.agrawal@salesforce.com',
  'lthakkar@salesforce.com', 'priyanshi.tyagi@salesforce.com', 'svagu@salesforce.com',
  'amankumar.aman@salesforce.com', 'dmanthri@salesforce.com', 'apragnya@salesforce.com', 'chetan.verma@salesforce.com', 'ruchi.kashyap@salesforce.com',
'vmurarishetty@salesforce.com', 'dsinghania@salesforce.com', 'pghildiyal@salesforce.com', 'drachumallu@salesforce.com',
'puneetkumar.ahuja@salesforce.com', 'chirag.anand@salesforce.com', 'csachan@salesforce.com', 'svagu@salesforce.com',
'saurabh.maurya@salesforce.com', 'kavitag@salesforce.com', 'nchandrakantdave@salesforce.com', 'ashirakanahalli@salesforce.com']

// Pages
const Main = React.lazy(() => import('./views/main/Main'))
const GoogleAuth = React.lazy(() => import('./views/googleAuth/GoogleAuth'))

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoggedIn: false,
      isUnderSriniOrg: true,
      isWhitelisted: true
    }
  }
  responseMessage = async (response) => {
    this.setState({ isLoggedIn: true })
    sessionStorage.setItem('isLoggedInToDS', 'YES')
    const dsSessionObject = {};
    const token_deoded = jwt_decode(response.credential)
    if(token_deoded.hd && token_deoded.hd.toLowerCase()==='salesforce.com') {
      sessionStorage.setItem('DS_LOGGEDIN_TOKEN', response.credential)
      sessionStorage.setItem('DS_LOGGEDIN_EMAIL', token_deoded.email)
      sessionStorage.setItem('lastSearchedType', 'Expert')
      this.updateLastLoggedInTime()
      await Promise.all([this.checkForSriniOrg(), this.checkForWhitelist()])
    } else {
      this.logoutDS()
    }
  }
  errorMessage = (error) => {
    sessionStorage.setItem('isLoggedInToDS', 'NO')
    sessionStorage.setItem('DS_LOGGEDIN_TOKEN', '')
    sessionStorage.setItem('DS_LOGGEDIN_EMAIL', '')
    this.setState({ isLoggedIn: false })
    sessionStorage.setItem('lastSearchedType', 'Expert')
  }
  checkForSriniOrg = async () => {
    const loggedInToken = sessionStorage.getItem('DS_LOGGEDIN_TOKEN');
    const loggedInUser = sessionStorage.getItem('DS_LOGGEDIN_EMAIL');

    let gusUrl = serviceEndPoint(`ds/profile/gus?token=${loggedInToken}&emails=${loggedInUser}`)
    axios.get(gusUrl)
       .then((response) => {
          const loggedInUserProfile = response.data.profiles[loggedInUser];
          //if(loggedInUser !== 'lthakkar@salesforce.com') {// This if to be deleted , its only for exception allow list
          if (loggedInUserProfile.ManagerId !== '005B0000005WsT8IAK' ) {
            if(WHITE_LISTED_EMAIL_IDS.indexOf(loggedInUser.toLowerCase()) === -1) {
              if (loggedInUserProfile.management_level_2_email !== 'stallapragada@salesforce.com'){
                this.setState({ isUnderSriniOrg: false })
              }
            }
          }
        })
        .catch((err) => {
        });
  }
  checkForWhitelist = async () => {
    const loggedInToken = sessionStorage.getItem('DS_LOGGEDIN_TOKEN')
    const loggedInUser = sessionStorage.getItem('DS_LOGGEDIN_EMAIL')
    let whitelistUrl = serviceEndPoint(`ds/admin/userRoles/validate_whitelist?token=${loggedInToken}`)
    axios.get(whitelistUrl)
      .then((response) => {
        const valid = response.data
        if (valid === false) {
          this.setState({ isWhitelisted: false })
        }

      })
      .catch((err) => {
        console.error(err)
      })
  }

  updateLastLoggedInTime(){
    const loggedInUser = sessionStorage.getItem('DS_LOGGEDIN_EMAIL');
    const userToken = sessionStorage.getItem('DS_LOGGEDIN_TOKEN');
    let payload = {identifier: loggedInUser,
      token: sessionStorage.getItem('DS_LOGGEDIN_TOKEN')
    }

    const params = new URLSearchParams(payload);
    const paramsString = params.toString();
    const url = serviceEndPoint(`ds/userlogging/${loggedInUser}?${paramsString}`);
    axios.post(url)
      .then(response => {
        // do nothing for now
      })
  }
  logoutDS = () => {
    const test = googleLogout({
      clientId: clientId(),
    });
    sessionStorage.clear();
    window.location.href = '/'
  }

  ifSessionExpiredRedirectToLoginPage = () => {
    const loggedInToken = sessionStorage.getItem('DS_LOGGEDIN_TOKEN')
    let currentDate = new Date();
    if (loggedInToken && loggedInToken.length>0) {
      const decodedJWT = jwt_decode(loggedInToken)
      if (decodedJWT.exp * 1000 < currentDate.getTime()) {
        this.logoutDS()
      }
    }
  }

  render() {
    this.ifSessionExpiredRedirectToLoginPage()
    const isLoggedIn = sessionStorage.getItem('isLoggedInToDS')
    let comp = ''
    if (isLoggedIn === 'YES') {
      comp = <Main />
    } else {
      comp = (
        <GoogleAuth
          responseMessage={this.responseMessage}
          errorMessage={this.errorMessage}
          hd="salesforce.com"
          flow ='auth-code'
        />
      )
    }
    return (<div className='p-0'><div> {comp} </div>

    <CModal visible={!this.state.isUnderSriniOrg && !this.state.isWhitelisted} alignment="center"
        backdrop="static"
        onClose={() => {this.logoutDS();this.setState({ isUnderSriniOrg: false, isWhitelisted: false })}}>
        <CModalHeader>
          <CModalTitle>Directory Services Not Accessible </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>Since you are not part of T&P Org you will not have access to Directory Services and hence will be logged out</p>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => {this.logoutDS();this.setState({ isUnderSriniOrg: false, isWhitelisted: false })}}>Okay</CButton>
        </CModalFooter>
      </CModal>
    </div>)
  }
}

export default App
